import "./footer.css";
import { Link, useLocation } from "react-router-dom";

function Footer(props) {
    var profile = props.profile;
    const location = useLocation();
    const data = [
        {
            icon: "button_trochoi1",
            iconactive: "button_trochoi2",
            title: "Trò chơi",
            to: "/"
        },

        {
            icon: "button_sotien1",
            iconactive: "button_sotien2",
            title: "Nạp tiền",
            to: "/recharge"
        },
        {
            icon: "button_lichsu1",
            iconactive: "button_lichsu2",
            title: "Khuyến mãi",
            to: "/notification"
        },
        {
            icon: "button_cuatoi1",
            iconactive: "button_cuatoi2",
            title: "Tài khoản",
            to: "/profile"
        },
        {
            icon: "button_chat1",
            iconactive: "button_chat2",
            title: "CSKH",
            to: "/service"
        }
    ];

    return (
        <>
            <div className="footer">
                {data.map((item, index) => (
                    <div className={location.pathname === item.to ? "footer_item active" : "footer_item"} key={index}>
                        <Link style={{ textDecoration: "none" }} to={item.to}>
                            <div className="icon_footer">
                                {location.pathname === item.to ? (
                                    <img alt="" src={require(`../../images/footer/${item.iconactive}.png`)} />
                                ) : (
                                    <img alt="" src={require(`../../images/footer/${item.icon}.png`)} />
                                )}
                            </div>
                            <div className="title_footer">{item.title}</div>
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
}
export default Footer;
